<script lang="ts">
import {inject, Ref} from 'vue'
import { FormServiceKey } from '../keys/FormServiceKey'
import type { FormServiceType } from "../services/form.service";

export default {
    name: 'FormFieldInit',
    props: {
        data: { type: Object, required: false },
        validation: { type: Object, required: false, default: null },
        validationRules: { type: Object, required: false, default: null },
        dataKey: { type: String, required: true },
        fullDataKey: { type: Boolean, required: false },
        showKey: { type: String, required: false, default: null },
        isClearable: { type: Boolean, required: false },
        translationName: { type: String, required: false },
        isChips: { type: Boolean, required: false },
        withTooltip: { type: Boolean, required: false },
        textInfoCard: { type: String, required: false, default: null },
        textTooltip: { type: String, required: false, default: null },
        noLabel: { type: Boolean, required: false },
        isMultiple: { type: Boolean, required: false },
        suffix: { type: String, required: false, default: null },
        placeholder: { type: String, required: false, default: null },
        type: { type: String, required: false, default: null },
        disabled: { type: Boolean, required: false },
        validationHasIndex: { type: Number, required: false, default: null },
        validationKey: { type: String, required: false, default: null },
        validationKeyEach: { type: String, required: false, default: null },
        appendInnerIcon: { type: String, required: false, default: null },
        customId: { type: String, required: false, default: null },
    },
    data(props) {
        // TODO handle non reactive service
        const formService = inject<Ref<FormServiceType | null>>(FormServiceKey, null)?.value ?? inject<FormServiceType | null>(FormServiceKey, null)
        const injectedData = formService?.getDataInput(props.dataKey) !== undefined ? formService?.getDataInput(props.dataKey) : null
        const injectedTranslationName = formService?.translationName ?? null
        const injectedValidationRules = formService?.validationRules ?? null
        const injectedValidation = formService?.getValidation(props.dataKey) ?? null
        return {
            formService,
            injectedData,
            injectedTranslationName,
            injectedValidationRules,
            injectedValidation,
        }
    },
    computed: {
        rule() {
            const validationRules = this.validationRules ?? this.injectedValidationRules
            if (validationRules) {
                if (this.validationKey) {
                    return validationRules[this.validationKey]
                }
                else {
                    if (this.showKey)
                        return validationRules[`${this.dataKey}.${this.showKey}`]

                    return validationRules[this.dataKey]
                }
            }
            else {
                return this.validation ? this.validation : null
            }
            // return this.validationRules ? this.validationKey ? this.validationRules[this.validationKey] : this.showKey && this.validationRules[this.dataKey] ? this.validationRules[`${this.dataKey}.${this.showKey}`] : this.validationRules[this.dataKey] : this.validation ? this.validation : null
        },
        value: {
            get() {
                if (this.dataKey && this.formService)
                    return this.formService.getDataInput(this.dataKey)
                if (this.dataKey) {
                    let nestedValue = this.data
                    const keys = this.fullDataKey ? [this.dataKey] : this.dataKey.split('.') // FullDataKey is to avoid name with . to be splitted (example : editRow CT & RO datas needs to be full)

                    keys.forEach((key) => {
                        if (this.isMultiple && Array.isArray(nestedValue))
                            nestedValue = nestedValue.map(val => val[key])
                        else
                            nestedValue = nestedValue && Object.prototype.hasOwnProperty.call(nestedValue, key) ? nestedValue[key] : undefined
                    })
                    return nestedValue
                }
                return null
            },
            set() { // newValue
                // ENABLE V-MODEL ON INPUTS, number had a refresh issue with just value prop.
                // On pourrait v-model tous les inputs et emit updatedata ici.
            },
        },
    },
    methods: {
        updateData(newValue: any) {
            if (this.formService)
                this.formService.updateData(this.dataKey, newValue)
        },
        triggerValidation() {
            const validation = this.validation ?? this.injectedValidation
            if (validation)
                validation?.$touch()
        },
        getErrors() {
            // SI VALIDATION LOOP & ON A L'INDEX comme par exemple contact.*.phone : required, contact.*.name : required ...
            let errors = []
            const validation = this.validation ?? this.injectedValidation ?? null
            if (validation && typeof this.validationHasIndex === 'number' && this.validationHasIndex > -1) {
                if (validation?.$each?.$response?.$errors[this.validationHasIndex])
                    errors = [...validation.$each.$response.$errors[this.validationHasIndex][this.validationKeyEach || this.dataKey].map(e => e.$message)]
                    // NEED TO HANDLE getInfo FOR NESTED LOOP DATA
            }
            else if (validation && validation?.$each) { // SI VALIDATION LOOP & ON A PAS L'INDEX (pour les validations autocomplete multiple qui n'ont pas de multichamps)
                if (validation?.$each?.$response?.$errors) {
                    validation.$each.$response.$errors.forEach((error) => {
                        if (error[this.validationKeyEach || this.dataKey]) {
                            const messages = error[this.validationKeyEach || this.dataKey].map(e => e.$message)
                            errors = [...errors, ...messages]
                        }
                    })
                }
            }
            else if (validation) {
                errors = [...validation.$errors.map(e => e.$message)]
            }
            return errors.length ? [...errors, this.getInfo()].join('. ') : errors
        },
        getMin() {
            return this.rule && Array.isArray(this.rule) ? this.rule.find(r => r.includes('min:'))?.replace('min:', '') : null
        },
        getMax() {
            return this.rule && Array.isArray(this.rule) ? this.rule.find(r => r.includes('max:'))?.replace('max:', '') : null
        },
        getInfo() {
            return this.rule && Array.isArray(this.rule) ? this.rule.find(r => r.match(/^info:/g))?.replace('info:', '') : null
        },
    },
}
</script>
