<script lang="ts">
import useValidate from '@vuelidate/core'
import { FormService } from '../../services/form.service'
// import {helpers} from '@vuelidate/validators'

interface dataToUpdate {
    key: string
    value: any
}

export default {
    // setup() {
    //     const { required } = useI18nValidators();
    //     const { email } = useI18nValidators();
    //     const { minLength } = useI18nValidators();
    //     return {
    //         required,
    //         email,
    //         minLength,
    //     }
    // },
    data() {
        return {
            v$: useValidate(),
            validators: {},
            documents: {}, // TO ADD DOCUMENTS FROM ALL FORM TO SAVE IT
        }
    },
    computed: {
        translationName() {
            return this.$attrs['translation-name']
        },
        data() {
            return this.$attrs.data as Record<string, any>
        },
        meta() {
            return this.$attrs.meta
        },
        validationRules() {
            return this.$attrs.rules
        },
        requestName() {
            return this.$attrs['request-name']
        },
        currentValidations() {
            if (this.data) {
                return (new FormService()).getVuelidateRules(this.validationRules) // TODO dont use formservice as a helper
            }
            return null
        },
    },
    mounted() {
    },
    validations() {
        return {
            ...this.currentValidations,
        }
    },
    methods: {
        updateDocuments(documents, type) {
            this.documents[type] = documents
        },
        updateData(toUpdate: dataToUpdate) {
            if (toUpdate) {
                const keys = Object.keys(toUpdate)
                keys.forEach((key) => {
                    const pathArray = key.split('.')

                    const lastNode = pathArray[pathArray.length - 1]
                    let node = this.data
                    pathArray.forEach((path, index) => node = index === pathArray.length - 1 ? node : node[path])
                    node[lastNode] = toUpdate[key]
                })
            }
        },
    },
}
</script>
