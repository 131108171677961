import { helpers } from '@vuelidate/validators'
import { useNuxtApp, useRoute, useRouter } from 'nuxt/app'
import useValidate, { Validation } from '@vuelidate/core'
import { reactive } from 'vue'
import type { BaseModel } from '../models/BaseModel'
import { useI18nValidators } from '../utils/validations/i18n-validators'

export class FormService {
    private readonly $translate: any
    private readonly $route: any
    readonly $router: any
    entity: BaseModel<any> | null
    fetchData: Record<string, string[]> = {}
    data: Record<string, string[]> = reactive({})
    validationRules: Record<string, string[]> = {}
    validation: Validation | null
    translationName: string | null = null
    loading: boolean = false
    saving: boolean = false
    currentKey: string | number | null = null

    constructor(entity: BaseModel<any> | null = null, currentKey: string | number | null = null) {
        const { $translate } = useNuxtApp()
        this.$translate = $translate
        this.$route = useRoute()
        this.$router = useRouter()
        this.currentKey = currentKey ?? this.getCurrentKey()
        this.entity = entity
    }

    translate(toTranslate: string): string {
        return this.$translate(`${this.translationName}.${toTranslate}`)
    }

    getVuelidateRules(metaRules: any): { data: Record<string, string[]> } {
        const validators = []
        // Auto create validators data from useI18nValidators
        const validatorsList = useI18nValidators()

        Object.keys(validatorsList).forEach((key) => {
            validators[key] = useI18nValidators()[key]
        })

        /* Pour chaque validation reçue du back, on va générer un objet avec les fonctions des validations traduites dans nuxt/composables/i18n-validators.ts
                    sous ce format : { nom du champ (name) : {
                            required : this.required,
                            email : this.email
                        }
                    }
                    S'il n'y a pas de function correspondante (string), la validation ne s'ajoute pas à l'objet
                */
        const obj = {}
        let globalKeyToInit = []

        if (metaRules) {
            let keys = Object.keys(metaRules)
            keys = keys.filter(key => !metaRules[key].includes('noFrontValidation'))
            //  split when * and filter contacts to create object with last elem)

            keys.forEach((key) => { // key = contacts.*.email
                let splitted, globalKey, dataKey

                if (key.includes('*')) { // IF ARRAY
                    splitted = key.split('.')
                    globalKey = splitted[0] // contacts
                    dataKey = splitted[splitted.length - 1] // name

                    globalKeyToInit.push(globalKey)

                    if (!obj[globalKey])
                        obj[globalKey] = {}

                    if (!obj[globalKey].each)
                        obj[globalKey].each = {}
                }
                else if (key.includes('.')) { // IF OBJECT
                    splitted = key.split('.')
                    globalKey = splitted[0] // contacts
                    dataKey = splitted[splitted.length - 1] // name

                    globalKeyToInit.push(globalKey)

                    if (!obj[globalKey])
                        obj[globalKey] = {}

                    // if (!obj[globalKey].each) {
                    //     obj[globalKey].each = {}
                    // }
                }

                const validations = {}

                metaRules[key].forEach((ruleKey) => { // ruleKey = required
                    let updatedRuleKey = ruleKey

                    if (ruleKey.includes('regex:'))
                        updatedRuleKey = 'regex'

                    if (ruleKey.includes('min:')) {
                        updatedRuleKey = 'minLength'
                        // validators.minLength.$validator(2)

                        if (metaRules[key].includes('integer')) { // IF RULES IS INT -> MAX / MIN IS VALUES, NOT LENGTH
                            updatedRuleKey = 'minValue'
                        }
                    }
                    if (ruleKey.includes('max:')) {
                        // validators.minLength.$validator(2)
                        updatedRuleKey = 'maxLength'

                        if (metaRules[key].includes('integer')) { // IF RULES IS INT -> MAX / MIN IS VALUES, NOT LENGTH
                            updatedRuleKey = 'maxValue'
                        }
                    }

                    if (validators[updatedRuleKey]) {
                        if (updatedRuleKey === 'regex') {
                            // OVERRIDE REGEX FUNCTION FROM i18n-validators to make it work
                            const splittedRegex = ruleKey.split(':') // ['regex', '/[abc]+/g']
                            const regexString = splittedRegex[splittedRegex.length - 1]
                            const lastSlash = regexString.lastIndexOf('/')
                            const regexValue = new RegExp(regexString.slice(1, lastSlash), regexString.slice(lastSlash + 1))

                            validations[updatedRuleKey] = {
                                $validator: helpers.regex(regexValue),
                                $message: this.$translate(`forms.validations.${updatedRuleKey}`), // , { value: splittedMaxMin[splittedMaxMin.length - 1] }
                            }
                        }
                        // SETUP VALIDATION
                        else if (updatedRuleKey === 'minLength' || updatedRuleKey === 'maxLength' || updatedRuleKey === 'minValue' || updatedRuleKey === 'maxValue') {
                            const splittedMaxMin = ruleKey.split(':')
                            validations[updatedRuleKey] = validators[updatedRuleKey].$validator(splittedMaxMin[splittedMaxMin.length - 1])
                            // SETUP TRANSLATE HERE BECAUSE CANT MAKE IT WORK FROM i18n-validators ATM
                            validations[updatedRuleKey] = {
                                ...validations[updatedRuleKey],
                                $message: this.$translate(`forms.validations.${updatedRuleKey}`, { value: splittedMaxMin[splittedMaxMin.length - 1] }),
                            }
                        }
                        else {
                            validations[updatedRuleKey] = validators[updatedRuleKey]
                        }
                        // ASSIGN VALIDATION
                        if (dataKey && obj[globalKey].each) {
                            obj[globalKey].each[dataKey] = validations
                        }
                        else if (dataKey) {
                            obj[globalKey][dataKey] = validations
                        }
                        else {
                            // OVERRIDE REQUIRED IF array IS IN RULES TO SETUP MINLENGTH(1) : required blocks validation on array
                            obj[key] = validations
                        }
                    }
                })
            })
        }
        globalKeyToInit = Array.from(new Set(globalKeyToInit))
        globalKeyToInit.forEach((key) => {
            if (obj[key]) {
                if (key) {
                    obj[key] = {
                        ...obj[key],
                        // minLength : validators.minLength.$validator(2),

                        $each: helpers.forEach({
                            ...obj[key].each,
                        }),
                    }
                }

                if (!obj[key].each)
                    delete obj[key].$each

                delete obj[key].each
            }
        })

        return { data: { ...obj } }
    }

    getCurrentKey(): null | string {
        return this.$route.params.k !== 'nouveau' ? this.$route.params.k : null
    }

    async init(): Promise<void> {
        if (this.currentKey)
            await this.getEntityData()
        else
            await this.getNewEntityData()
    }

    async getEntityData(): Promise<void> {
        this.loading = true
        const result = await this.entity.get()
        this.setData(result)
    }

    async getNewEntityData(query: { [key: string]: any } = {}): Promise<void> {
        this.loading = true
        const result = await this.entity.getCreate(query)
        this.setData(result)
    }

    setData(result: BaseModel<any>): void {
        Object.assign(this.entity, result)
        this.translationName = result.translationKey
        Object.assign(this.fetchData, result.getEditableDataFormat())
        Object.assign(this.data, reactive({ ...this.fetchData }))
        Object.assign(this.validationRules, result.getRules())
        this.loading = false
        this.setValidation()
    }

    updateData(dataKey: string, newValue: any): any {
        return this.data[dataKey] = newValue
    }

    getDataInput(dataKey: string): any {
        return this.data[dataKey]
    }

    setValidation(): void {
        const vuelidateRules = reactive(this.getVuelidateRules(this.validationRules))
        if (!this.validation)
            this.validation = useValidate(vuelidateRules.data, this.data) as unknown as Validation
        else
            this.validation.$reset()
    }

    getValidation(dataKey: string): Validation {
        return this.validation?.value ? this.validation?.value[dataKey] : this.validation[dataKey]
    }

    async submitData(formData: FormData | null = null): Promise<BaseModel<any>> {
        const isDataValid = await this.validation.$validate()
        if (!isDataValid)
            throw this.validation.$errors

        this.saving = true
        if (isDataValid && this.currentKey) {
            const res = await this.entity.put(formData ?? this.data)
            if (res) {
                this.setData(res)
            }
        }

        else if (isDataValid && !this.currentKey) {
            const res = await this.entity.post(formData ?? this.data)
            if (res) {
                this.$router.push(`/${this.entity.endPoint}`)
                return null
            }
        }

        this.validation.$reset()

        this.saving = false
        return this.entity
    }

    resetData(): void {
        this.data = reactive({ ...this.fetchData })
        this.validation.$reset()
    }

    async deleteEntity(): Promise<BaseModel<any> | void> {
        return await this.entity.delete()
    }
}

export type FormServiceType = InstanceType<typeof FormService>
