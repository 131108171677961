<script lang="ts">
import InfoCard from '../InfoCard.vue'
import FormTooltip from './Tooltip.vue'

export default {
    name: 'FormLabel',
    components: { InfoCard, FormTooltip },
    computed: {
        showLabel() {
            return !this.noLabel || (this.labelSidePosition && !this.rule)
        },
        labelSidePosition() { // FOR YESNO
            return this.$parent.labelSidePosition
        },
        noLabel() {
            return this.$parent.noLabel
        },
        textInfoCard() {
            return this.$parent.textInfoCard
        },
        textTooltip() {
            return this.$parent.textTooltip
        },
        textCaption() {
            return this.$parent.textCaption
        },
        dataKey() {
            return this.$parent.dataKey
        },
        customId() {
            return this.$parent.customId
        },
        translationName() {
            return this.$parent.translationName || this.$parent.injectedTranslationName
        },
        validation() {
            return this.$parent.validation || this.$parent.injectedValidation
        },
        rule() {
            return this.$parent.rule
        },
    },
}
</script>

<template>
    <div :class="rule ? 'mb-1' : showLabel && 'mb-3'">
        <template v-if="showLabel">
            <!-- WHEN FORM -->
            <label
                v-if="rule"
                class="v-label"
                :class="rule && 'mb-1'"
                :for="customId ?? dataKey"
            >
                {{ $translate && $translate(`${translationName}.${dataKey}`) }}

                <template v-if="validation && validation.required">
                    <span v-html="$config.public.form_label_required_text || '*'" />
                </template>

            </label>
            <!-- WHEN DETAIL -->
            <p
                v-else
                class="v-label"
                :for="dataKey"
            >
                {{ $translate && $translate(`${translationName}.${dataKey}`) }}
                <!-- <template v-if="validation && validation.required">
          <span v-html="$config.public.form_label_required_text || '*'" />
        </template> -->
            </p>
        </template>

        <div v-if="textCaption" class="text-caption text-medium-emphasis pb-1">
            {{ textCaption }}
        </div>

        <FormTooltip
            v-if="textTooltip && rule"
            :text="textTooltip"
        />

        <template v-if="textInfoCard">
            <div v-if="$config.public.form_label_tooltip_as_pure_text" class="text-caption text-medium-emphasis pb-1">
                {{ textInfoCard }}
            </div>
            <InfoCard
                v-else
                :text="textInfoCard"
            />
        </template>

        <slot />
    </div>
</template>
